/* site.js */

// set to false if you want that older ShareThis widget.
var switchTo5x = true;

var cgi = new Object();
    cgi.http_host = location.host;
    cgi.http_user_agent = navigator.userAgent;
    cgi.http_cookie = document.cookie;
    cgi.https = (location.protocol == 'https:\/\/') ? 'on' : 'off';
    cgi.path_info = (location.pathname.indexOf('?') != -1) ? location.pathname.substring(0, location.pathname.indexOf('?')) : location.pathname;
    cgi.query_string = (location.search) ? ((location.search.indexOf('##') != -1) ? location.search.substring(1, location.search.indexOf('##')) : location.search.substring(1)) : '';
    cgi.script_name = cgi.path_info;

var qs = location.search.substring(1);
var nv = qs.split('&');
var url = new Object();
for(i = 0; i < nv.length; i++)
{
  var eq = nv[i].indexOf('=');
  if(typeof url[nv[i].substring(0,eq).toLowerCase()] == 'undefined')
    url[nv[i].substring(0,eq).toLowerCase()] = [];
  url[nv[i].substring(0,eq).toLowerCase()].push(unescape(nv[i].substring(eq + 1)));
}
for(i in url)
  url[i] = url[i].toString();

var tokens = new Object();
    tokens['?'] = (url.cfid && url.cftoken) ? '?cfid=' + url.cfid + '&cftoken=' + url.cftoken : '';
    tokens['&'] = (url.cfid && url.cftoken) ? '&cfid=' + url.cfid + '&cftoken=' + url.cftoken : '';
if(url.debug && url.debug == 1)
{
  tokens['?'] = (tokens['?'] == '') ? '?debug=1' : tokens['?'] + '&debug=1';
  tokens['&'] = tokens['&'] + '&debug=1';
}

! function($){
    $(function(){
        $(window).on('scroll', function(){
            var $this = $(this);
            if(this.scrollY > 0){
                $('body').addClass('scrolled');
            }else{
                $('body').removeClass('scrolled');
            }
            
            var $animated = $('span[data-animate=true]');
            $animated.each(function () {
                var $this = $(this);
                var $parent = $this.parents('.row');
                var $header = $('header > div > div');
                var top_of_scrolled_viewport = window.scrollY + $header.height();
                var bottom_of_scrolled_viewport = window.scrollY + $(window).height();
                var top_of_animated_obj = $parent.offset().top;
                var bottom_of_animated_obj = $parent.offset().top + $parent.height();
                
                var is_visible = bottom_of_scrolled_viewport >= bottom_of_animated_obj && top_of_scrolled_viewport <= top_of_animated_obj;
                var is_animated = $this.data('animated') || false;

                if(is_visible){
                    if(!is_animated){
                        var start = $this.data('start');
                        var end = $this.data('end');
                        $this.data('animated', true);
                        $this.prop('index', start).animate({
                            index: end
                        }, {
                            duration: 1000
                          , easing: 'linear'
                          , step: function (now) {
                                $(this).text(numeral(Math.ceil(now)).format('0,0'));
                            }
                        });
                    }
                }else{
                    $this.data('animated', false);
                }
            });
        });
        
        /* initalize tooltips */
        $(document.body).tooltip({
            selector: '[data-toggle=tooltip], [data-tooltip]'
          , container: 'body'
        });

        // no more form submision with enter key
        $(document).keydown(function(e) {
            var element = e.target.nodeName.toLowerCase();
            if(element === 'input'){
                if(e.keyCode === 13){
                    var $e = $(e.target);
                    var is_tags_input = ($e.attr('role') === 'tags-input' || $e.data('role') === 'tags-input' || $e.parents('.bootstrap-tagsinput').length > 0) ? true : false;
                    var $btn = $e.parents('[data-enter]');
                    if(!is_tags_input){
                  	  if($btn !== 'undefined' && $btn.length !== 0){
                            var btn = $btn.data('enter') || '';
                            if(btn !== ''){
                          	  if(typeof btn === 'boolean'){
                          		  if(!btn){
                              		  e.preventDefault();
                              		  e.stopPropagation();
                          		  }
                          	  }else{
                          		  e.preventDefault();
                          		  e.stopPropagation();
                          		  $(btn).click();
                          	  }
                            }
                        }else{
                            return false;
                        }
                    }
                }
            }
        });
        
        $('[data-focus]').each(function(){
            var $this = $(this);
            var input = $this.data('focus');
            var $input = $(input);
            window.setTimeout(function(){
          	  $input.focus();
            }, 250);
            return;
        });
        
        $('[data-calculate=total], [data-negative=false]').on('focus', function(evt){
            // initialize number unformatting on focus
            var $this = $(this);
            var value = $this.val();
            var decimal = coalesce($this.data('decimal'), true);
            var mask = coalesce($this.data('negative-mask'), (decimal) ? '0.00' : '0');
            if(!$this.prop('readonly')){
                if(value !== ''){
                    value = numeral().unformat(value);
                    $this.val(numeral(value).format(mask));
                }
            }
        }).on('blur change', function(evt){
            // initialize number formatting on blur or change
            var $this = $(this);
            var value = $this.val();
            var decimal = coalesce($this.data('decimal'), true);
            var mask = coalesce($this.data('negative-mask'), (decimal) ? '0,0.00' : '0,0');
            if(!$this.prop('readonly')){
                if(value !== ''){
                    value = numeral().unformat(value);
                    $this.val(numeral(value).format(mask));
                    if(evt.type === 'blur'){
                        $this.change();
                    }
                }
            }
        });

        // initialize non-negative inputs
        $('[data-negative=false]').on('change', function(){
            var $this = $(this);
            var value = parseFloat($this.val());
            if(!isNaN(value) && value < 0){
                $this.val(0);
            }
        });
        
        /* find all img within img-bg and switch to background image */
        $('.img-bg > img').each(function(){
          var $this = $(this);
          var $parent = $this.parent();
          $parent.width($this.width());
          $parent.height($this.height());
          $parent.css('background-image', 'url(' + $this.attr('src') + ')');
          $this.remove();
        });
        
        var $carousels = $('.carousel-inner');
        $carousels.each(function(){

        });
        
        /* look for and handle full-height components */
        $('.fill-height').each(function(){
            var $this = $(this);
            var oParent = $(this).parent();
//            console.log(oParent.width(), ' > ', $this.outerWidth(), oParent.width() > $this.outerWidth());
            if(oParent.width() > $this.outerWidth() || (oParent.width() >= $this.outerWidth() && oParent.hasClass('fill-height')))
            {
                if(oParent.hasClass('fill-height')){
                    if($this.prev().hasClass('panel-heading')){
                        $this.css('height', (oParent.height() - $this.prev().outerHeight()) + 'px');
                    }else if($this.next().hasClass('panel-footer')){
                        $this.css('height', (oParent.height() - $this.next().outerHeight()) + 'px');
                    }else{
                        $this.css('height', '100%');
                    }
                }else{
                    $this.outerHeight(oParent.height());
                }
            }else if(!oParent.hasClass('fill-height')){
                $this.removeClass('fill-height');
            }
        });
        
        /* look for and handle all col-square components */
        $('.col-square').each(function(){
            var $this = $(this);
            var parent = $this.data('square-parent');
            var $parent = null;
            if(typeof parent !== 'undefined'){
                $parent = $this.parents(parent);
            }else{
                $parent = $this.parent();
            }
            var width = $parent.data('square-width');
            if(typeof width === 'undefined'){
                width = $this.outerWidth();
                $parent.data('square-width', width);
            }
            $this.outerHeight(width);
        });

        /* look for required items and add input-group-addon to indicate to the user */
        $('form div.required').each(function(){
            var $this = $(this);
            var $inputgroup = $this.find('.input-group:not(.no-required-addon)');
            var $formcontrol = $this.find('.form-control:not(.no-required-addon)');
            if(!$inputgroup.length && $formcontrol.length){
                var $hasttwrapper = $this.find('.twitter-typeahead');
                $formcontrol.wrap('<div class="input-group"></div>');
                if($hasttwrapper.length){
                    $formcontrol.each(function(){
                        $input = $(this);
                        $parent = $input.parent();
                        $parent.attr('style', $input.attr('style'));
                        if($input.attr('style').indexOf('background-color') !== -1 || $input.attr('style').indexOf('background') !== -1){
                            $input.attr('style', '');
                            $input.css('background-color', $parent.css('background-color'));
                        }else{
                            $input.attr('style', '');
                        }
                    });
                }
                $inputgroup = $formcontrol.parent();
            }
            if($inputgroup.length){
                $hasrequired = $inputgroup.find('span.input-group-addon.required');
                $hasinputgroupbtn = $inputgroup.find('.input-group-btn:last-child');
                if(!$hasrequired.length){
                    if(!$hasinputgroupbtn.length){
                        $inputgroup.append('<span class="input-group-addon required text-bold" data-toggle="tooltip" title="Required"><i class="far fa-asterisk fa-fw" aria-required="true" aria-describedby=""></i></span>');
                    }else{
                        $inputgroupbtn = $inputgroup.find('.input-group-btn');
                        $inputgroupbtn.before('<span class="input-group-addon required text-bold" data-toggle="tooltip" title="Required"><i class="far fa-asterisk fa-fw" aria-required="true" aria-describedby=""></i></span>');
                    }
                }
            }
        });

        /* find and initialize date pickers */
        $('.input-group.date, .input-group-btn.date').each(function(){
            var $this = $(this);
            var datesDisabled = $this.data('date-dates-disabled') || false;
            if(datesDisabled !== false){
                datesDisabled = datesDisabled.split(',');
            }
            $this.datepicker({
                autoclose: true
              , orientation: 'auto'
              , showOnFocus: false
              , toggleActive: false
              , datesDisabled: datesDisabled
            }).on('show', function(){
    //            var $this = $(this);
    //            console.log($this.hasClass('disabled'));
            });
        })
        /* deal with modals */
        $('[data-toggle=modal]').each(function(index, item){
            var oModalToggle = $(this);
            var sHref = oModalToggle.data('href') || oModalToggle.attr('href') || '';
            if(location.protocol !== 'https:' || sHref.toLowerCase().substr(0, 8) === 'https://' || sHref.toLowerCase().substr(0, 1) === '/' || sHref === ''){
                var oTarget = $(oModalToggle.data('target') || (sHref && sHref.replace(/.*(?=#[^\s]+$)/, ''))) //strip for ie7
                if(oTarget.length === 0){
                    idTarget = oModalToggle.data('target').replace('#', '');
                    sTitle = oModalToggle.data('title');
                    if(typeof sTitle === 'undefined'){
                        sTitle = '';
                    }
                    bClose = oModalToggle.data('close') === 'true' || oModalToggle.data('close') === true;
                    sSize = oModalToggle.data('size') || '';
                    if(sSize !== ''){
                        sSize = 'modal-' + sSize;
                    }
                    nWidth = parseInt(oModalToggle.data('width'), 10);
                    nHeight = parseInt(oModalToggle.data('height'), 10);
                    nMinWidth = parseInt(oModalToggle.data('min-width'), 10);
                    nMinHeight = parseInt(oModalToggle.data('min-height'), 10);
                    bImage = (oModalToggle.data('image') === 'true' || oModalToggle.data('image') === true);
                    bRemote = sHref !== '' && (oModalToggle.data('remote') === 'true' || oModalToggle.data('remote') === true);
                    bDestroy = (oModalToggle.data('destroy') === 'true' || oModalToggle.data('destroy') === true);
                    bWidth = true;
                    bHeight = true;
                    if(sSize !== ''){
                        bWidth = false;
                        bHeight = false;
                    }
                    oModalToggle.data('remote', false);
                    oModal = newModal({
                        idTarget: idTarget
                      , sTitle: sTitle
                      , bClose: bClose
                      , sHref: sHref
                      , sSize: sSize
                      , bRemote: bRemote
                      , bImage: bImage
                      , bWidth: bWidth
                      , bHeight: bHeight
                      , nWidth: nWidth
                      , nHeight: nHeight
                      , nMinWidth: nMinWidth
                      , nMinHeight: nMinHeight
                    });
                    oModal.data('toggle', oModalToggle);
                    oModal.data('href', sHref);
                    oModal.data('remote', bRemote);
                    
                    if(location.hash && location.hash.length > 1 && location.hash.substr(1) === idTarget){
                        oModal.modal('show');
                    }
                    
                    $('#' + idTarget).on('shown.bs.modal', function(e){
                        oModal = $(this);
                        oModalDialog = oModal.children('.modal-dialog');
                        oIframe = $('#' + oModal[0].id + ' .modal-body iframe');
                        if($(document.body).width() >= oModalDialog.outerWidth()){
                            if(oIframe && oIframe.length > 0){
                                oIframe.attr("src", oIframe.data('src'));
                                oIframe.on('load', function(){
                                    $this = $(this);
//                                    console.log($this, $this.parents('.modal-body'));
                                    $this.parents('.modal-body').removeClass('loading');
                                })
                            }else{
                                var oContent = $('#' + oModal[0].id + '-content');
                                if(oContent){
                                    oContent.load(oModal.data('href'));
                                }
                            }
                        }else{
                            e.preventDefault();
                            oModal.modal('hide');
                            oWindow = window.open(oIframe.data('src'), '_blank');
                            if(typeof oWindow === 'undefined' || oWindow === null){
                                oModalPopup = newModal({
                                    idTarget: idTarget + '-popup'
                                  , sTitle: sTitle
                                  , bClose: bClose
                                  , sHTML: '<p class="alert alert-warning">We tried opening the link you clicked in a new window, but your browser blocked it. Please try again using the button below.</p><p class="text-center"><a href="' + sHref + '" target="_blank" class="btn btn-primary btn-blue">Visit "' + sTitle + '"</a></p>'
                                });
                                oModalPopup.modal('show');
                            }
                        }
                    });
                    if(bDestroy){
                        $('#' + idTarget).on('hidden.bs.modal', function(){
                            oModal.remove();
                        });
                    }
                    eHide = oModalToggle.data('event-hide');
                    if(typeof eHide !== 'undefined' && eHide.length !== 0){
                        $('#' + idTarget).on('hide.bs.modal', function(){
                            eval(eHide);
                        });
                    }
                    eHidden = oModalToggle.data('event-hidden');
                    if(typeof eHidden !== 'undefined' && eHidden.length !== 0){
                        $('#' + idTarget).on('hidden.bs.modal', function(){
                            eval(eHidden);
                        });
                    }
                    $('#' + idTarget).on('shown.bs.modal', function(){
                        var $this = $(this);
                        if(!$this.children('div.modal-dialog').hasClass('modal-lg') && !$this.children('div.modal-dialog').hasClass('modal-sm')){
                            $this.css('overflow-y', 'auto');
                        }
                    });
                    $('#' + idTarget).on('hidden.bs.modal', function(){
                        var $this = $(this);
                        if(!$this.children('div.modal-dialog').hasClass('modal-lg') && !$this.children('div.modal-dialog').hasClass('modal-sm')){
                            $(this).css('overflow-y', 'scroll');
                        }
                        oIframe = $('#' + oModal[0].id + ' .modal-body iframe');
                        if(oIframe){
                            oIframe.attr("src", 'about:blank');
                        }
                    });
                }
//            }else{
//                oModalToggle.removeAttr('data-toggle');
            }
        });

    });
}(window.jQuery);

function newModal(options){
    var options = options || {};
    var keys = ['idTarget', 'sTitle', 'sSize', 'bClose', 'sHref', 'bImage', 'bRemote', 'sHTML', 'bWidth', 'bHeight', 'nWidth', 'nHeight', 'nMinWidth', 'nMinHeight'];
    for(var i = 0; i < keys.length; i++){
        if(typeof options[keys[i]] === 'undefined'){
            if(keys[i].indexOf('id') || keys[i].indexOf('s') === 0){
                options[keys[i]] = '';
            }else if(keys[i].indexOf('n') === 0){
                options[keys[i]] = 0;
            }else if(keys[i].indexOf('b') === 0){
                options[keys[i]] = false;
            }else{
                options[keys[i]] = null;
            }
        }
    }

    if(options.nWidth !== 0 && options.nWidth < options.nMinWidth){
        options.nMinWidth = options.nWidth;
    }
    if(options.nHeight !== 0 && options.nHeight < options.nMinHeight){
        options.nMinHeight = options.nHeight;
    }
    var styles = {
            'dialog': 'margin: 0 auto;'
          , 'body': ''
          , 'content': ''
        };
    if(options.bWidth){
        styles.body += 'width: 100%;';
    }
    if(options.sHref !== ''){
        styles.body += 'padding: 0;';
    }
    if(options.nWidth){
        styles.dialog += 'width: ' + (options.nWidth + 42) + 'px;';
    }else if(options.bWidth){
        styles.dialog += 'width: 100%;';
    }
    if(options.nHeight){
        styles.dialog += 'height: ' + (options.nHeight + 118) + 'px; padding: 0;';
        if(options.bImage){
            styles.body += 'height: ' + (options.nHeight + 40) + 'px;';
        }else{
            styles.body += 'height: ' + options.nHeight + 'px;';
        }
    }else if(options.bHeight){
        styles.dialog += 'height: 100%; padding: 0;';
        styles.content += 'height: 100%;';
        styles.body += 'position: absolute; top: 56px; bottom: 0;';
    }
    if(options.nMinWidth){
        if(options.bImage){
          styles.dialog += 'min-width: ' + (options.nMinWidth + 63) + 'px;';
          styles.content += 'min-width: ' + (options.nMinWidth + 42) + 'px;';
        }else{
          styles.dialog += 'min-width: ' + (options.nMinWidth + 63) + 'px;';
          styles.content += 'min-width: ' + (options.nMinWidth + 42) + 'px;';
        }
        styles.body += 'min-width: ' + options.nMinWidth + 'px;';
    }
    if(options.nMinHeight){
        styles.dialog += 'min-height: ' + (options.nMinHeight + 118) + 'px;';
        styles.content += 'min-height: ' + (options.nMinHeight + 56) + 'px;';
        styles.body += 'min-height: ' + options.nMinHeight + 'px;';
    }
    htmlModal = '<div class="modal fade" id="' + options.idTarget + '" tabindex="-1" role="dialog" aria-labelledby="' + options.idTarget + 'Label" aria-hidden="true">';
    htmlModal +=  '<div class="modal-dialog';
    if(options.sSize !== ''){
        htmlModal += ' ' + options.sSize;
    }
    htmlModal +=  '"';
    if(styles.dialog !== ''){
        htmlModal +=  ' style="' + styles.dialog + '"';
    }
    htmlModal +=  '>';
    htmlModal +=    '<div class="modal-content"';
    if(styles.content !== ''){
        htmlModal +=    ' style="' + styles.content + '"';
    }
    htmlModal +=    '>';
    if(options.bClose || options.sTitle !== ''){
        htmlModal +=  '<div class="modal-header">';
        if(options.bClose){
            htmlModal +=    '<button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" aria-hidden="true">&times;</button>';
        }
        htmlModal +=    '<h4 class="modal-title">' + options.sTitle + '</h4>';
        htmlModal +=  '</div>';
    }
    htmlModal +=      '<div class="modal-body';
    if(options.sHref !== ''){
        htmlModal +=  ' loading';
    }
    htmlModal +=      '"';
    if(styles.body !== ''){
        htmlModal +=      ' style="' + styles.body + '"';
    }
    htmlModal +=      '>';
    if(options.sHref !== ''){
        if(options.bImage){
            htmlModal +=    '<div style="position: absolute; top: 20px; right: 20px; bottom: 20px; left: 20px; background-image: url(' + options.sHref + '); background-repeat: no-repeat; background-position: 50% 50%; background-size: contain"></div>';
        }else{
            htmlModal +=    '<div id="' + options.idTarget + '-content" style="width: 100%; height: 100%; padding: 20px 0 0 0;' + ((options.bRemote && options.sSize === '') ? ' overflow: auto' : '') + '">';
            if(!options.bRemote){
                htmlModal +=      '<iframe width="100%" height="100%" frameborder="0" data-src="' + options.sHref + '"></iframe>';
            }
            htmlModal +=    '</div>';
        }
    }else if(options.sHTML !== ''){
        htmlModal +=     options.sHTML;
    }
    htmlModal +=      '</div>';
    htmlModal +=    '</div>';
    htmlModal +=  '</div>';
    htmlModal += '</div>';
    $(document.body).append(htmlModal);
    return $('#' + options.idTarget);
}

function calculatePayment(principal, interest, payments){
    var returnValue = '';
    var x = 0;
    var af = 0;
    var raf = 0;
    var monthly = 0;
    var interest_free_months = 0;
    if(arguments.length >= 4){
        interest_free_months = arguments[3];
    }
    if(interest > 0){
        x = Math.pow(1 + interest, payments);
        af =  (x * interest) / (x - 1);
        raf = af / (1 + (interest_free_months * af));
        monthly = principal * raf;
//        monthly = (principal * x) / (x - 1);
    }else{
        monthly = principal / payments;
    }
    
    if(!isNaN(monthly) && (monthly != Number.POSITIVE_INFINITY) && (monthly != Number.NEGATIVE_INFINITY)){
        returnValue  = round(monthly);
    }
    return returnValue;
}

// This simple method rounds a number to two decimal places.
function round(x) {
  return Math.round(x * 100) / 100;
}

function isDate(d){
  try{
      if(Object.prototype.toString.call(d) !== '[object Date]')
          return false;
      return !isNaN(d.getTime());

//      return typeof date === 'object' && new Date(date).toString() !== 'Invalid Date' && !isNaN(new Date(date));
  }catch(err) {
      return false;
  }
}

function coalesce(){
    var returnValue = null;
    for(i = 0; i < arguments.length; i++){
        if(arguments[i] !== null && typeof arguments[i] !== 'undefined'){
            returnValue = arguments[i];
            break;
        }
    }
    return returnValue;
}

/* radio.js */

class RadioGroup {
    constructor(groupNode) {
      this.groupNode = groupNode;
  
      this.radioButtons = [];
  
      this.firstRadioButton = null;
      this.lastRadioButton = null;
  
      var rbs = this.groupNode.querySelectorAll('[role=radio]');
  
      for (var i = 0; i < rbs.length; i++) {
        var rb = rbs[i];
  
        rb.tabIndex = -1;
        rb.setAttribute('aria-checked', 'false');
  
        rb.addEventListener('keydown', this.handleKeydown.bind(this));
        rb.addEventListener('click', this.handleClick.bind(this));
        rb.addEventListener('focus', this.handleFocus.bind(this));
        rb.addEventListener('blur', this.handleBlur.bind(this));
  
        this.radioButtons.push(rb);
  
        if (!this.firstRadioButton) {
          this.firstRadioButton = rb;
        }
        this.lastRadioButton = rb;
      }
      this.firstRadioButton.tabIndex = 0;
    }
  
    setChecked(currentItem) {
      for (var i = 0; i < this.radioButtons.length; i++) {
        var rb = this.radioButtons[i];
        rb.setAttribute('aria-checked', 'false');
        rb.tabIndex = -1;
      }
      currentItem.setAttribute('aria-checked', 'true');
      $('#industry').val(currentItem.id);
      
      currentItem.tabIndex = 0;
      currentItem.focus();
    }
  
    setCheckedToPreviousItem(currentItem) {
      var index;
  
      if (currentItem === this.firstRadioButton) {
        this.setChecked(this.lastRadioButton);
      } else {
        index = this.radioButtons.indexOf(currentItem);
        this.setChecked(this.radioButtons[index - 1]);
      }
    }
  
    setCheckedToNextItem(currentItem) {
      var index;
  
      if (currentItem === this.lastRadioButton) {
        this.setChecked(this.firstRadioButton);
      } else {
        index = this.radioButtons.indexOf(currentItem);
        this.setChecked(this.radioButtons[index + 1]);
      }
    }
  
    /* EVENT HANDLERS */
  
    handleKeydown(event) {
      var tgt = event.currentTarget,
        flag = false;
  
      switch (event.key) {
        case ' ':
        case 'Enter':
          this.setChecked(tgt);
          flag = true;
          break;
  
        case 'Up':
        case 'ArrowUp':
        case 'Left':
        case 'ArrowLeft':
          this.setCheckedToPreviousItem(tgt);
          flag = true;
          break;
  
        case 'Down':
        case 'ArrowDown':
        case 'Right':
        case 'ArrowRight':
          this.setCheckedToNextItem(tgt);
          flag = true;
          break;
  
        default:
          break;
      }
  
      if (flag) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  
    handleClick(event) {
      this.setChecked(event.currentTarget);
    }
  
    handleFocus(event) {
      event.currentTarget.classList.add('focus');
    }
  
    handleBlur(event) {
      event.currentTarget.classList.remove('focus');
    }
  }
  
  // Initialize radio button group
  window.addEventListener('load', function () {
    var radios = document.querySelectorAll('[role="radiogroup"]');
    for (var i = 0; i < radios.length; i++) {
      new RadioGroup(radios[i]);
    }
  });

/* 
 *   Control:   carousel-prev-next button events
 *
 *   Desc:   Carousel widget with Previous and Next Buttons that implements ARIA Authoring Practices
 *
 */

var CarouselPreviousNext = function (node, options) {
  // merge passed options with defaults
  options = Object.assign(
    { moreaccessible: false, paused: false, norotate: false },
    options || {}
  );

  // a prefers-reduced-motion user setting must always override autoplay
  var hasReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
  if (hasReducedMotion.matches) {
    options.paused = true;
  }

  /* DOM properties */
  this.domNode = node;

  this.carouselItemNodes = node.querySelectorAll('.item');

  this.containerNode = node.querySelector('.carousel-items');
  this.liveRegionNode = node.querySelector('.carousel-items');
  //this.pausePlayButtonNode = null;
  this.previousButtonNode = null;
  this.nextButtonNode = null;

  this.playLabel = 'Start automatic slide show';
  this.pauseLabel = 'Stop automatic slide show';

  /* State properties */
  this.hasUserActivatedPlay = false; // set when the user activates the play/pause button
  this.isAutoRotationDisabled = options.norotate; // This property for disabling auto rotation
  this.isPlayingEnabled = !options.paused; // This property is also set in updatePlaying method
  this.timeInterval = 5000; // length of slide rotation in ms
  this.currentIndex = 0; // index of current slide
  this.slideTimeout = null; // save reference to setTimeout

  // Pause Button

  /*
  var elem = document.querySelector('.carousel .controls button.rotation');
  if (elem) {
    this.pausePlayButtonNode = elem;
    this.pausePlayButtonNode.addEventListener(
      'click',
      this.handlePausePlayButtonClick.bind(this)
    );
  }
  */

  // Previous Button

  var elem = document.querySelector('.carousel .controls button.previous');
  if (elem) {
    this.previousButtonNode = elem;
    this.previousButtonNode.addEventListener(
      'click',
      this.handlePreviousButtonClick.bind(this)
    );
    this.previousButtonNode.addEventListener(
      'focus',
      this.handleFocusIn.bind(this)
    );
    this.previousButtonNode.addEventListener(
      'blur',
      this.handleFocusOut.bind(this)
    );
  }

  // Next Button

  elem = document.querySelector('.carousel .controls button.next');
  if (elem) {
    this.nextButtonNode = elem;
    this.nextButtonNode.addEventListener(
      'click',
      this.handleNextButtonClick.bind(this)
    );
    this.nextButtonNode.addEventListener(
      'focus',
      this.handleFocusIn.bind(this)
    );
    this.nextButtonNode.addEventListener(
      'blur',
      this.handleFocusOut.bind(this)
    );
  }

  // Carousel item events

  for (var i = 0; i < this.carouselItemNodes.length; i++) {
    var carouselItemNode = this.carouselItemNodes[i];

    // support stopping rotation when any element receives focus in the tabpanel
    carouselItemNode.addEventListener('focusin', this.handleFocusIn.bind(this));
    carouselItemNode.addEventListener(
      'focusout',
      this.handleFocusOut.bind(this)
    );

    var imageLinkNode = carouselItemNode.querySelector('.img-bg a');

    if (imageLinkNode) {
      imageLinkNode.addEventListener(
        'focus',
        this.handleImageLinkFocus.bind(this)
      );
      imageLinkNode.addEventListener(
        'blur',
        this.handleImageLinkBlur.bind(this)
      );
    }
  }

  // Handle hover events
  this.domNode.addEventListener('mouseover', this.handleMouseOver.bind(this));
  this.domNode.addEventListener('mouseout', this.handleMouseOut.bind(this));

  // initialize behavior based on options

  this.enableOrDisableAutoRotation(options.norotate);
  this.updatePlaying(!options.paused && !options.norotate);
  this.setAccessibleStyling(options.moreaccessible);
  this.rotateSlides();
};

/* Public function to disable/enable rotation and if false, hide pause/play button*/
CarouselPreviousNext.prototype.enableOrDisableAutoRotation = function (
  disable
) {
  this.isAutoRotationDisabled = disable;
  //this.pausePlayButtonNode.hidden = disable;
};

/* Public function to update controls/caption styling */
CarouselPreviousNext.prototype.setAccessibleStyling = function (accessible) {
  if (accessible) {
    this.domNode.classList.add('carousel-moreaccessible');
  } else {
    this.domNode.classList.remove('carousel-moreaccessible');
  }
};

CarouselPreviousNext.prototype.showCarouselItem = function (index) {
  this.currentIndex = index;

  for (var i = 0; i < this.carouselItemNodes.length; i++) {
    var carouselItemNode = this.carouselItemNodes[i];
    if (index === i) {
      carouselItemNode.classList.add('active');
    } else {
      carouselItemNode.classList.remove('active');
    }
  }
};

CarouselPreviousNext.prototype.previousCarouselItem = function () {
  var nextIndex = this.currentIndex - 1;
  if (nextIndex < 0) {
    nextIndex = this.carouselItemNodes.length - 1;
  }
  this.showCarouselItem(nextIndex);
};

CarouselPreviousNext.prototype.nextCarouselItem = function () {
  var nextIndex = this.currentIndex + 1;
  if (nextIndex >= this.carouselItemNodes.length) {
    nextIndex = 0;
  }
  this.showCarouselItem(nextIndex);
};

CarouselPreviousNext.prototype.rotateSlides = function () {
  if (!this.isAutoRotationDisabled) {
    if (
      (!this.hasFocus && !this.hasHover && this.isPlayingEnabled) ||
      this.hasUserActivatedPlay
    ) {
      this.nextCarouselItem();
    }
  }

  this.slideTimeout = setTimeout(
    this.rotateSlides.bind(this),
    this.timeInterval
  );
};

CarouselPreviousNext.prototype.updatePlaying = function (play) {
  this.isPlayingEnabled = play;

  if (play) {
    //this.pausePlayButtonNode.setAttribute('aria-label', this.pauseLabel);
    //this.pausePlayButtonNode.classList.remove('play');
    //this.pausePlayButtonNode.classList.add('pause');
    //this.liveRegionNode.setAttribute('aria-live', 'off');
  } else {
    //this.pausePlayButtonNode.setAttribute('aria-label', this.playLabel);
    //this.pausePlayButtonNode.classList.remove('pause');
    //this.pausePlayButtonNode.classList.add('play');
    //this.liveRegionNode.setAttribute('aria-live', 'polite');
  }
};

/* Event Handlers */

CarouselPreviousNext.prototype.handleImageLinkFocus = function () {
  this.liveRegionNode.classList.add('focus');
};

CarouselPreviousNext.prototype.handleImageLinkBlur = function () {
  this.liveRegionNode.classList.remove('focus');
};

CarouselPreviousNext.prototype.handleMouseOver = function (event) {
 /* if (!this.pausePlayButtonNode.contains(event.target)) {
    this.hasHover = true;
  }*/
};

CarouselPreviousNext.prototype.handleMouseOut = function () {
  this.hasHover = false;
};

/* EVENT HANDLERS */

CarouselPreviousNext.prototype.handlePausePlayButtonClick = function () {
  this.hasUserActivatedPlay = !this.isPlayingEnabled;
  this.updatePlaying(!this.isPlayingEnabled);
};

CarouselPreviousNext.prototype.handlePreviousButtonClick = function () {
  this.previousCarouselItem();
};

CarouselPreviousNext.prototype.handleNextButtonClick = function () {
  this.nextCarouselItem();
};

/* Event Handlers for carousel items*/

CarouselPreviousNext.prototype.handleFocusIn = function () {
  this.liveRegionNode.setAttribute('aria-live', 'polite');
  this.hasFocus = true;
};

CarouselPreviousNext.prototype.handleFocusOut = function () {
  if (this.isPlayingEnabled) {
    this.liveRegionNode.setAttribute('aria-live', 'off');
  }
  this.hasFocus = false;
};

/* Initialize Carousel and options */

window.addEventListener(
  'load',
  function () {
    var carouselEls = document.querySelectorAll('.carousel');
    var carousels = [];

    // set example behavior based on
    // default setting of the checkboxes and the parameters in the URL
    // update checkboxes based on any corresponding URL parameters
    var checkboxes = document.querySelectorAll(
      '.carousel-options input[type=checkbox]'
    );
    var urlParams = new URLSearchParams(location.search);
    var carouselOptions = {};

    // initialize example features based on
    // default setting of the checkboxes and the parameters in the URL
    // update checkboxes based on any corresponding URL parameters
    checkboxes.forEach(function (checkbox) {
      var checked = checkbox.checked;

      if (urlParams.has(checkbox.value)) {
        var urlParam = urlParams.get(checkbox.value);
        if (typeof urlParam === 'string') {
          checked = urlParam === 'true';
          checkbox.checked = checked;
        }
      }

      carouselOptions[checkbox.value] = checkbox.checked;
    });

    carouselEls.forEach(function (node) {
      carousels.push(new CarouselPreviousNext(node, carouselOptions));
    });

    // add change event to checkboxes
    checkboxes.forEach(function (checkbox) {
      var updateEvent;
      switch (checkbox.value) {
        case 'moreaccessible':
          updateEvent = 'setAccessibleStyling';
          break;
        case 'norotate':
          updateEvent = 'enableOrDisableAutoRotation';
          break;
      }

      // update the carousel behavior and URL when a checkbox state changes
      checkbox.addEventListener('change', function (event) {
        urlParams.set(event.target.value, event.target.checked + '');
        window.history.replaceState(
          null,
          '',
          window.location.pathname + '?' + urlParams
        );

        if (updateEvent) {
          carousels.forEach(function (carousel) {
            carousel[updateEvent](event.target.checked);
          });
        }
      });
    });
  },
  false
);


// Next Button

elem = document.querySelector('#testimonials-carousel .container .carousel-control-prev');
if (elem) {
  this.nextButtonNode = elem; alert(elem);
  this.nextButtonNode.addEventListener(
    'click',
    this.handleNextButtonClick.bind(this)
  );
  this.nextButtonNode.addEventListener(
    'focus',
    this.handleFocusIn.bind(this)
  );
  this.nextButtonNode.addEventListener(
    'blur',
    this.handleFocusOut.bind(this)
  );
}
